<script>
import Layout from "@/router/layouts/template-pemohon";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Detail Permohonan",
      items: [
        {
          text: "Dashboards",
          active: true,
        },
      ],
      permohonan_id: this.$route.params.permohonan_id,
      permohonan: [],
      permohonan_data_teknis: null,
      permohonan_dokumen: [],
      status_sekarang: [],
      status_selanjutnya: [],
    };
  },
  methods: {
    getFile(files) {
      var win = window.open(process.env.VUE_APP_BACKEND_URL + files, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
  },
  mounted() {
    Swal.fire({
      title: '<i class="fas fa-spinner fa-spin"></i>',
      text: "Loading...",
      showConfirmButton: false,
    });
    let self = this;
    axios
      .get(process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/detail", {
        params: {
          permohonan_id: self.permohonan_id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      })
      .then((response) => {
        var res_fix = response.data.data;
        self.permohonan = res_fix.permohonan;
        // permohonan_data_teknis
        self.permohonan_timeline = res_fix.permohonan_timeline;
        self.status_sekarang = res_fix.status_sekarang;
        self.status_selanjutnya = res_fix.status_selanjutnya;
        self.permohonan_dokumen = res_fix.permohonan_dokumen;
      })
      .catch((e) => {
        console.log(e.response);
        this.axiosCatchError = e.response.data;
        // Swal.close()
      });
    axios
      .get(process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/data-teknis", {
        params: {
          permohonan_id: self.permohonan_id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      })
      .then((response) => {
        self.permohonan_data_teknis = response.data;
        Swal.close();
      })
      .catch((e) => {
        console.log(e.response);
        this.axiosCatchError = e.response.data;
        Swal.close();
      });
  },
  created() {},
};
</script>

<template>
  <Layout>
    <PageHeader />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header top-radius-15 bg-success">
            <h4 class="my-0 text-center text-white">
              <b>Detail Permohonan</b>
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="text-center"><b>Data Umum</b></div>
                <table
                  class="table table-sm table-borderless mb-0 table-striped table-hover"
                >
                  <tbody>
                    <tr v-if="permohonan?.p_id_izin">
                      <th style="width: 250px">Id Izin</th>
                      <th style="width: 15px">:</th>
                      <td>
                        {{ permohonan.p_id_izin }}
                      </td>
                    </tr>
                    <tr v-if="permohonan?.p_kode_izin">
                      <th style="width: 250px">Kode Izin</th>
                      <th style="width: 15px">:</th>
                      <td>
                        {{ permohonan.p_kode_izin }}
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 250px">Nomor Surat Permohonan</th>
                      <th style="width: 15px">:</th>
                      <td>
                        {{ permohonan.p_nomor_surat_permohonan }}
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 250px">Tanggal Surat</th>
                      <th style="width: 15px">:</th>
                      <td>
                        {{ permohonan.p_tanggal_surat_permohonan }}
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 250px">Perihal</th>
                      <th style="width: 15px">:</th>
                      <td>
                        {{ permohonan.p_perihal }}
                      </td>
                    </tr>
                    <tr>
                      <th>Jenis Perizinan</th>
                      <th style="width: 15px">:</th>
                      <td>
                        <span
                          v-if="permohonan?.perizinan?.perizinanjenis?.mpj_nama"
                          >{{
                            permohonan.perizinan.perizinanjenis.mpj_nama
                          }}</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <th>Perizinan</th>
                      <th style="width: 15px">:</th>
                      <td>
                        <span v-if="permohonan?.perizinan?.mp_nama">{{
                          permohonan.perizinan.mp_nama
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Sub Perizinan</th>
                      <th style="width: 15px">:</th>
                      <td>
                        <span v-if="permohonan?.perizinansub?.mps_nama">{{
                          permohonan.perizinansub.mps_nama
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Status Permohonan</th>
                      <th style="width: 15px">:</th>
                      <td>
                        <badge
                          v-if="
                            status_sekarang?.mspk_status_kategori == 'Draft'
                          "
                          class="badge bg-warning"
                          >{{ status_sekarang.mspk_status_kategori }}</badge
                        >
                        <badge
                          v-else-if="
                            status_sekarang?.mspk_status_kategori == 'Selesai'
                          "
                          class="badge bg-success"
                          >{{ status_sekarang.mspk_status_kategori }}</badge
                        >
                        <badge v-else class="badge bg-info">{{
                          permohonan.status_permohonan
                        }}</badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-center"><b>Data Teknis</b></div>
                <div v-html="permohonan_data_teknis"></div>
                <div class="text-center"><b>Dokumen Persyaratan</b></div>
                <table class="table table-sm table-bordered mb-0">
                  <thead class="bg-dark text-white text-center">
                    <tr>
                      <th>No</th>
                      <th>Nama Dokumen</th>
                      <th>Tanggal Awal</th>
                      <th>Tanggal Akhir</th>
                      <th>#</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        permohonan_dokumen_val, permohonan_dokumen_key
                      ) in permohonan_dokumen"
                      :key="permohonan_dokumen_key"
                    >
                      <td>{{ permohonan_dokumen_key + 1 }}</td>
                      <td>
                        <span
                          v-if="
                            permohonan_dokumen_val.dokumen_persyaratan.mdp_nama
                          "
                          >{{
                            permohonan_dokumen_val.dokumen_persyaratan.mdp_nama
                          }}</span
                        >
                      </td>
                      <td>{{ permohonan_dokumen_val.pd_mdp_tanggal_awal }}</td>
                      <td>{{ permohonan_dokumen_val.pd_mdp_tanggal_akhir }}</td>
                      <td>
                        <div
                          class="btn btn-warning btn-sm"
                          v-on:click="getFile(permohonan_dokumen_val.pd_path)"
                        >
                          <i class="fa fa-eye"></i> Lihat
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header top-radius-15 bg-success">
            <h4 class="my-0 text-center text-white"><b>Linimasa</b></h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12" style="overflow-x: scroll">
                <ul class="timeline timeline-horizontal">
                  <li
                    class="timeline-item"
                    v-for="(
                      value_timelime, index_timelime
                    ) in permohonan_timeline"
                    :key="index_timelime"
                  >
                    <div
                      class="timeline-badge danger"
                      v-if="
                        value_timelime.pt_status_kategori == 'ditolak' ||
                        value_timelime.pt_status_kategori == 'dibatalkan'
                      "
                    >
                      <i class="fa fa-times" style="margin-top: 12px"></i>
                    </div>
                    <div class="timeline-badge success" v-else>
                      <i class="fa fa-check" style="margin-top: 12px"></i>
                    </div>

                    <div class="timeline-panel">
                      <div class="timeline-heading">
                        <b class="timeline-title">
                          {{ value_timelime.role.role }}</b
                        >
                        <p>
                          <small class="text-muted"
                            ><i class="fa fa-clock-o"></i>
                            {{ value_timelime.pt_tanggal }}</small
                          >
                        </p>
                      </div>
                      <div class="badge bg-info">
                        {{ JSON.parse(value_timelime.pt_status_json).msp_nama }}
                      </div>
                      <br />
                      <div
                        class="timeline-body"
                        v-if="value_timelime.pt_catatan"
                      >
                        <b>Catatan : </b><br />
                        <p>{{ value_timelime.pt_catatan }}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
